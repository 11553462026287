import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DownOutlined, CheckOutlined } from "@ant-design/icons";
import { Select, Divider } from "antd";
import UserReferenceField from "../Users/UI/UserReferenceField";
import Notification from "../Notification/Notification";
import "./SiteLayout.css";
import {
  getYearOfDisplayUserByUser,
  getSelectedOffsetYear,
} from "../YearsUser/Application/YearsUser.business";
import { getYearOfDisplayByUserSelector } from "../YearsUser/Infrastructure/YearsUser.reducer";
/// import { saveSelectedOffsetYear } from "../YearsUser/Infrastructure/YearsUser.store";
import { getSelectedOffsetYearSelector } from "../YearsUser/Infrastructure/YearsUser.reducer";


const UserMenu = () => {
  const yearOfDisplay = useSelector(getYearOfDisplayByUserSelector);
  const [selectedYear, setSelectedYear] = useState(null);
  const offsetYear = useSelector(getSelectedOffsetYearSelector);

  const onChange = (value) => {
    setSelectedYear(value);
    // saveSelectedOffsetYear(value);
    getSelectedOffsetYear(value);
  };

  const onSearch = (value) => {
    setSelectedYear(value);
    // saveSelectedOffsetYear(value);
    getSelectedOffsetYear(value);
  };

  useEffect(() => {
    setSelectedYear(offsetYear);
    getYearOfDisplayUserByUser();
  }, []);

  const yearOptions = Array.isArray(yearOfDisplay?.years)
    ? yearOfDisplay.years.map((year) => ({
        value: year,
        label: year,
      }))
    : [];

  return (
    <Notification
      notificacionStyle="userDetails"
      icon={<DownOutlined className="downOutlined" />}
      type="text"
      description={
        <div>
          <UserReferenceField
            stylesUsername="usernameSecond"
            occupationStyle="occupationSecond"
            dashboard
            container="containerSecond"
            containerText="containerTextSecond"
            occupation="Dev"
            dot
            size={64}
            children={({ roleObject, selectedRol, handleRoleClick }) => (
              <div className="grid-notification">
                <div className="dividir-user">
                  <Divider className="dividir-user-text">
                    Tipo de usuario
                  </Divider>
                  <div className="container-role">
                    {roleObject?.role?.map((role) => (
                      <span
                        className="role"
                        key={role.roleName}
                        onClick={() => handleRoleClick(role.roleName)}
                      >
                        <div>
                          <span className="container-role-text">
                            {role.translatedRoles}
                          </span>
                          {role.roleName === selectedRol && (
                            <CheckOutlined className="check-outlined" />
                          )}
                        </div>
                      </span>
                    ))}
                  </div>

                  {roleObject?.role?.map((role) =>
                    role.translatedRoles === "Partner" ? (
                      <div key={role.roleName}>
                        <Divider className="dividir-user-text">
                          Año de visualización
                        </Divider>
                        <div className="dividir-container-select">
                          <Select
                            className="select-custom-dropdown"
                            showSearch
                            placeholder="Seleccionar año"
                            optionFilterProp="label"
                            defaultValue ={selectedYear}
                            onChange={onChange}
                            onSearch={onSearch}
                            dropdownClassName="custom-dropdown"
                            options={yearOptions}
                          />
                        </div>
                      </div>
                    ) : null
                  )}
                </div>
              </div>
            )}
          />
        </div>
      }
    />
  );
};

export default UserMenu;
