import store from "../../Redux/store";
import { setYearOfDisplayByUser,selectOffsetYear} from "./YearsUser.reducer";
import Storage from "../../Data/Domain/Storage";

export function saveYearOfDisplayByUser(records) {
  store.dispatch(setYearOfDisplayByUser(records));
}
export function saveSelectedOffsetYear(offsetYear) { 
  store.dispatch(selectOffsetYear(offsetYear));
  if (offsetYear) {
    new Storage("offsetYear").setItem(offsetYear);
  }
}