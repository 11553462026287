import { saveYearOfDisplayByUser ,saveSelectedOffsetYear} from "../Infrastructure/YearsUser.store";
import { getYearOfDisplayUserByUserLogic } from "./YearsUser.logic";

export async function getYearOfDisplayUserByUser(id) {
  const years = await getYearOfDisplayUserByUserLogic(id);
  saveYearOfDisplayByUser(years);
}
export async function getSelectedOffsetYear(OffsetYear) {
  const offsetYear = await saveSelectedOffsetYear(OffsetYear);
  saveSelectedOffsetYear(offsetYear);
  return offsetYear;
}