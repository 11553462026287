import API from "../../Data/Domain/API";
import URL from "../../Data/Domain/URL";
import Storage from "../../Data/Domain/Storage";
import store from "../../Redux/store";
import { getSelectedOffsetYearSelector } from "../../YearsUser/Infrastructure/YearsUser.reducer";

export async function getOffsets(id) {
  const reduxOffsetYear = getSelectedOffsetYearSelector(store.getState());
  const offsetYear = reduxOffsetYear || new Storage("offsetYear").getItem(); 

  const endpoint = offsetYear
    ? URL.buildUrl(`users/${id}/offsets?force=true&year=${offsetYear}`)
    : URL.buildUrl(`users/${id}/offsets`);

  return await new API(endpoint).get();
}

export async function patchManageData(id, data) {
  return await new API(URL.buildUrl(`users/${id}/offsets`)).patch(data);
}
