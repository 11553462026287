import Response from "../../Data/Domain/Response";
import { getYearOfDisplayByUser } from "./YearsUser.service";
import Year from "../Domain/YearUser";

export async function getYearOfDisplayByUserRepository(id) {
  const data = await getYearOfDisplayByUser(id);
  return new Response(data, Year).getSingle();

}



