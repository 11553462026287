import API from "../../Data/Domain/API";
import URL from "../../Data/Domain/URL";
import Storage from "../../Data/Domain/Storage";

export async function getAreas(params) {
  return await new API(URL.setParams(URL.buildUrl("areas"), params)).get();
}

// export async function getArea() {
//   const id = new Storage("id").getItem();
//   return await new API(URL.buildUrl(`users/${id}/areas`)).get();
// }

export async function getAreasByUser(id) {
  const internal_id = id || new Storage("id").getItem();
  const endpoint = URL.buildUrl(`users/${internal_id}/areas`);
  return await new API(endpoint).get();
}

export async function postAreas({ name }) {
  return await new API(URL.buildUrl("areas")).post({ name });
}

export async function putAreas(id, data) {
  const url = URL.setId(URL.buildUrl("areas"), id);
  return await new API(url).put(data);
}

export async function deleteArea(id) {
  return await new API(URL.setId(URL.buildUrl("areas"), id)).delete();
}
