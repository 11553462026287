import { createSlice } from "@reduxjs/toolkit";
import Storage from "../../Data/Domain/Storage";

export const slice = createSlice({
  name: "years",
  initialState: {
    yearByUser: [], 
    offsetYear: null, 

  },

  reducers: {
    setYearOfDisplayByUser: (state, { payload: yearByUser }) => {
      return {
        ...state,
        yearByUser,
      };
    },
    selectOffsetYear: (state, { payload: offsetYear }) => {
      return {
        ...state,
        offsetYear,
      };
    },
  },
});

export const { setYearOfDisplayByUser,selectOffsetYear } = slice.actions;
export default slice.reducer;

export const getYearOfDisplayByUserSelector = (store) => store.years.yearByUser;
export const getSelectedOffsetYearSelector = (store) => {
  const offsetYear = store.years.offsetYear;
  return offsetYear || new Storage("offsetYear").getItem();
};